<template>
	<div>
		<LeftNav :currentPath="'follow'"></LeftNav>

		<div class="mywz_publish margin_auto justify_content_sb mine_content_right">
			<div class="mywz_publish_header">
				<div class="mywz_publish_header_title">我的关注</div>
			</div>
			<div style="height: 20px"></div>
			<div class="mywz_publish_line"></div>

			<div class="my_follow_list" v-if="mywzList.length > 0">
				<div
					class="my_follow_list_item"
					v-for="(item, index) in mywzList"
					:key="index"
				>
					<div class="my_follow_list_item_1">
						<div>
							<img :src="item.users[0].avatar_img" alt="" />
						</div>
						<div class="my_follow_list_item_1_1">
							<div class="my_follow_list_item_1_1_1">{{
								item.users[0].nickname
							}}</div>
							<div class="my_follow_list_item_1_1_2">234</div>
						</div>
					</div>

					<div class="my_follow_list_item_2">
						{{ item.users[0].desc }}
					</div>
					<div
						class="my_follow_list_item_3"
						@click="followAuthorFun(item.author_id, 0)"
					>
						已关注
					</div>
				</div>
			</div>
			<div
				v-if="hasData && mywzList.length == 0"
				class="mywz_publish_list_empty"
			>
				<div class="mywz_publish_list_empty_icon">
					<img
						src="https://res.metaera.hk/resources/assets/images/active/63.png"
						alt=""
					/>
				</div>
				<div class="mywz_publish_list_empty_text">
					还没有关注的作者，现在就去浏览吧
				</div>
				<div class="mywz_publish_list_empty_action" @click="gotoPubish()"
					>浏览文章
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getCategoryList, delInformation } from "@/api/article";
import { myFollow, followAuthor } from "@/api/user";
import { newBus } from "@/components/pc/topHeader2.vue";

import { getUserId } from "@/utils/auth";
import { getToken } from "@/utils/auth";

import LeftNav from "../leftNav";
export default {
	name: "",
	components: {
		LeftNav,
	},
	data() {
		return {
			mywzList: [],
			hasData: false,
			localLanguage: 1,
		};
	},
	created() {
		const hasToken = getToken();
		console.log(hasToken);
		if (hasToken) {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "繁体中文") {
				this.localLanguage = 1;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 0;
			}
			this.myFollow();
		} else {
			this.$router.push({ path: "/" });
		}
	},
	mounted() {
		newBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "繁体中文") {
				this.localLanguage = 1;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 0;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "繁体中文") {
			this.localLanguage = 1;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 0;
		}
	},
	methods: {
		followAuthorFun(id, type) {
			followAuthor({ author_id: id, is_status: type })
				.then((res) => {
					if (res.code == 200) {
						this.$message.success("操作成功！");
						this.myFollow();
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		gotoPubish() {
			this.$router.push({
				path: "/",
			});
		},

		myFollow() {
			myFollow({
				id: getUserId(),
			})
				.then((res) => {
					if (res.code == 200) {
						this.hasData = true;
						this.mywzList = res.data.list;
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
	},
};
</script>
<style scoped>
@import url("../../../assets/person.css");
.mywz_publish {
	border-radius: 20px;
	opacity: 1;
	box-shadow: 6.43px 21.04px 35px 0px rgba(54, 62, 147, 0.051);
	padding-left: 30px;
	padding-right: 30px;
	padding-top: 30px;
	padding-bottom: 30px;
}

.mywz_publish_header {
	display: flex;
	justify-content: space-between;
}
.mywz_publish_header_title {
	font-size: 24px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #fff;
	display: inline-block;
	align-content: center;
	align-items: center;
	text-align: center;
}
.mywz_publish_header_acticon {
	font-size: 16px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #fff;
	display: inline-block;
	align-content: center;
	align-items: center;
	text-align: center;
	border-radius: 10px;

	width: 120px;
	height: 40px;
	background: rgba(239, 245, 254, 0.1);
}

.mywz_publish_tips {
	font-size: 14px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 500;
	color: #999999;
	margin-top: 10px;
	margin-bottom: 10px;
}
.mywz_publish_line {
	width: 100%;
	height: 1px;
	opacity: 0.2;
	background: #d8d8d8;
}

.mywz_publish_list_empty {
	width: 100%;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
}
.mywz_publish_list_empty_icon {
	width: 100px;
	height: 100px;
	margin-top: 64px;
	object-fit: cover;
}
.mywz_publish_list_empty_icon img {
	width: 100%;
	height: 100%;
}
.mywz_publish_list_empty_text {
	font-size: 16px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 500;
	color: #fff;
	margin-top: 36px;
}
.mywz_publish_list_empty_action {
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	border-radius: 6px 6px 6px 6px;
	opacity: 1;
	color: white;
	margin-top: 36px;
	width: 133px;
	height: 44px;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: row;
	cursor: pointer;
	font-size: 16px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 500;
	align-items: center;
}
</style>

<style scoped>
.my_follow_list {
	margin-top: 30px;
	display: flex; /* 使用 flexbox 布局 */
	flex-wrap: wrap; /* 允许子元素换行 */
	gap: clamp(16px, calc(100vw * 24 / 1400), 30px); /* 子元素之间的间距 */
}

.my_follow_list_item {
	width: clamp(160px, calc(100vw * 180 / 1400), 240px);
	height: 190px;
	border-radius: 8px;
	background: rgba(255, 255, 255, 0.1);
	box-shadow: 6.43px 21.04px 35px 0px rgba(54, 62, 147, 0.051);
	padding-left: 11px;
	padding-right: 11px;
	padding-top: 15px;
	padding-bottom: 15px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.my_follow_list_item_1 {
	display: flex;
}

.my_follow_list_item_1 img {
	width: 50px;
	height: 50px;
	border-radius: 50px;
}

.my_follow_list_item_1_1 {
	display: block;
	align-items: center;
	align-content: center;
	margin-left: 10px;
}

.my_follow_list_item_1_1_1 {
	font-size: 16px;
	font-weight: 500;
	line-height: 18px;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
	margin-bottom: 10px;
}
.my_follow_list_item_1_1_2 {
	font-size: 13px;
	font-weight: normal;
	line-height: 13px;
	letter-spacing: -0.009000000000000001em;
	font-variation-settings: "opsz" auto;
	color: #a2a6bb;
}

.my_follow_list_item_2 {
	font-size: 12px;
	font-weight: normal;
	line-height: 16px;
	letter-spacing: 0em;
	/* 描述字段 */
	color: #656878;
	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
}

.my_follow_list_item_3 {
	font-size: 12px;
	font-weight: 600;
	line-height: 12px;
	letter-spacing: 0em;
	/* 纯白 */
	color: #ffffff;
	display: inline-block;
	align-content: center;
	align-items: center;
	text-align: center;
	border-radius: 10px;

	width: 70px;
	height: 26px;
	background: rgba(239, 245, 254, 0.1);
}
</style>
